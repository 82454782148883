.messages_box_area {
  /* display: grid; */
  grid-template-columns: 4fr 8fr;
  grid-gap: 30px;
}
.bg-primary {
  background-color: #4c2fbf !important;
  color: #fff;
}
.bg-secondary {
  background-color: #2e9de4 !important;
  color: #fff;
}
.bg-grey {
  background-color: #919191 !important;
  color: #fff;
}
.bg-grey-light {
  background-color: #d1d1d1 !important;
  color: #1a1a1a;
}
.bg-success {
  background-color: #089303 !important;
  color: #fff;
}
.bg-success-light {
  background-image: radial-gradient(#fff, #fff, #66e262) !important;
  color: #fff;
  color: #fff;
}
.bg-info {
  background-color: #06b5dd !important;
  color: #fff;
}
.bg-warning {
  background-color: #ffc717 !important;
  color: #2c323f;
}
.bg-danger {
  background-color: #ff0000 !important;
  color: #fff;
}
.bg-danger-light {
  background-image: radial-gradient(#fff, #fff, rgb(255, 130, 130)) !important;
  color: #fff;
}
.bg-light {
  background-color: #f8f5fd !important;
  color: #2c323f;
}
.bg-dark {
  background-color: #2c323f !important;
  color: #fff;
}
.bg-black {
  background-color: #000 !important;
  color: #fff;
}
.bg-light-black {
  background-color: #1a1a1a !important;
  color: #fff;
}
.footer_part {
  text-align: center;
  padding-bottom: 0;
  padding-top: 6px;
  background: 0 0;
  padding-left: 270px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 15px;
}
.footer_part.fixed_footer {
  position: fixed;
  z-index: 99;
  border-radius: 0;
  padding-bottom: 0;
  background: 0 0;
}
.footer_part.fixed_footer .footer_iner {
  border-top: 2px solid #373063;
  background: #fff !important;
}
.footer_part.full_footer {
  padding-left: 136px;
}
.footer_part.default_footer {
  background: #f5f6ff;
}
@media (max-width: 575.98px) {
  .messages_box_area {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 991px) {
  .messages_box_area {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .messages_box_area {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .messages_box_area {
    grid-template-columns: 6fr 6fr;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1679px) {
  .messages_box_area {
    grid-template-columns: 5fr 7fr;
  }
}
.messages_box_area .messages_list .serach_field_2 {
  width: 100%;
}
.messages_box_area .messages_list ul {
  margin-top: 30px;
  margin-bottom: 30px;
}
.messages_box_area .messages_list ul li a {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #f1f5fa;
  padding: 15px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 5px;
  margin-bottom: 5px;
}
.messages_box_area .messages_list ul li a .message_pre_left {
  display: flex;
  align-items: center;
}
.messages_box_area .messages_list ul li a .message_pre_left .message_preview_thumb {
  position: relative;
}
.messages_box_area .messages_list ul li a .message_pre_left .message_preview_thumb img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.messages_box_area .messages_list ul li a .message_pre_left .message_preview_thumb .round-10 {
  border: 2px solid #f8f8fc;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 5px;
  display: inline-block;
  height: 12px;
  width: 12px;
}
.messages_box_area .messages_list ul li a .message_pre_left .messges_info {
  padding-left: 20px;
}
.messages_box_area .messages_list ul li a .message_pre_left h4 {
  font-size: 14px;
  font-weight: 500;
  color: #222;
  margin-bottom: 6px;
}
.messages_box_area .messages_list ul li a .message_pre_left p {
  font-size: 13px;
  font-weight: 300;
  color: #676b84;
  margin-bottom: 0;
}
.messages_box_area .messages_list ul li a .messge_time span {
  font-size: 12px;
  font-weight: 300;
  color: #192434;
  white-space: nowrap;
}
.messages_box_area .messages_list ul li:hover a {
  background: #f1f5fa;
}
.messages_box_area .messages_chat .messge_time span {
  white-space: nowrap;
}
.messages_box_area .messages_chat .single_message_chat {
  margin-bottom: 50px;
  padding-right: 20%;
}
@media (max-width: 575.98px) {
  .messages_box_area .messages_chat .single_message_chat {
    padding-right: 0%;
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .messages_box_area .messages_chat .single_message_chat {
    padding-right: 0%;
    margin-bottom: 30px;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .messages_box_area .messages_chat .single_message_chat {
    padding-right: 0%;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .messages_box_area .messages_chat .single_message_chat {
    padding-right: 0%;
    margin-bottom: 30px;
  }
}
.messages_box_area .messages_chat .single_message_chat .message_content_view {
  background: #f5f6ff;
  border-radius: 10px;
  padding: 30px;
}
.messages_box_area .messages_chat .single_message_chat .message_content_view.red_border {
  border: 1px solid #373063;
  background: #373063;
}
.messages_box_area .messages_chat .single_message_chat .message_content_view.red_border span,
.messages_box_area .messages_chat .single_message_chat .message_content_view.red_border p {
  color: #fff;
}
.messages_box_area .messages_chat .single_message_chat .message_content_view span {
  display: block;
  margin: 17px 0;
}
.messages_box_area .messages_chat .single_message_chat .message_content_view p {
  font-size: 13px;
  font-weight: 300;
  color: #676b84;
  line-height: 26px;
}
.messages_box_area .messages_chat .single_message_chat .message_pre_left {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.messages_box_area .messages_chat .single_message_chat .message_pre_left .message_preview_thumb img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.messages_box_area .messages_chat .single_message_chat .message_pre_left .messges_info {
  padding-left: 20px;
}
.messages_box_area .messages_chat .single_message_chat .message_pre_left h4 {
  font-size: 16px;
  font-weight: 500;
  color: #222;
  margin-bottom: 2px;
}
.messages_box_area .messages_chat .single_message_chat .message_pre_left p {
  font-size: 12px;
  font-weight: 300;
  color: #676b84;
  margin-bottom: 0;
}
.messages_box_area .messages_chat .single_message_chat.sender_message {
  padding-right: 0%;
  padding-left: 20%;
}
@media (max-width: 575.98px) {
  .messages_box_area .messages_chat .single_message_chat.sender_message {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 30px;
  }
}
@media (max-width: 991px) {
  .messages_box_area .messages_chat .single_message_chat.sender_message {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 30px;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .messages_box_area .messages_chat .single_message_chat.sender_message {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .messages_box_area .messages_chat .single_message_chat.sender_message {
    padding-right: 0%;
    padding-left: 0%;
    margin-bottom: 30px;
  }
}
.messages_box_area .messages_chat .single_message_chat.sender_message .message_pre_left {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.messages_box_area .messages_chat .single_message_chat.sender_message .messges_info {
  padding-left: 0;
  padding-right: 20px;
  text-align: right;
}
.messages_box_area .messages_chat .message_send_field {
  padding-top: 50px;
  display: grid;
  grid-template-columns: auto 110px;
  grid-gap: 10px;
}
@media (max-width: 575.98px) {
  .messages_box_area .messages_chat .message_send_field {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }
}
.messages_box_area .messages_chat .message_send_field input {
  color: #676b84;
  font-size: 13px;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  padding-left: 25px;
  border: 1px solid #eee1e2;
  padding-right: 15px;
}
.messages_box_area .messages_chat .message_send_field input::placeholder {
  color: #676b84;
  font-weight: 300;
  opacity: 1;
}
